body,html,#root{
  height:100%;
}

.App {
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#toast-root{
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 340px;
  padding: 10px 4px;
  position: fixed;
  display: flex;
  flex-direction:column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 310;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

#root .input-range__slider {
  border-color: #187fba;
  /* border-width:2px; */
  background-color: #187fba;
  margin-top: -0.60rem;
  height: 0.8rem;
  width: 0.8rem;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

#root .input-range__track {
  height: 0.2rem;
}

#root .input-range__track{
  background-color: rgba(0,0,0,0.1);
}

#root .input-range__track--active {
  background-color: #187fba;
}

#root .input-range__label{
  color:#4c4c4c;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.7rem;
}

.input-range__slider-container:first-of-type .input-range__label-container {
  left: -100%;
}

.input-range__slider-container:nth-of-type(2) .input-range__label-container {
  left: 0;
}

.crisis-plan-content p {
  margin: 5px 0;
}

.rec.rec-arrow {
  background: #fafafa;
  border-radius: 0;
  width: 25px;
  height: 25px;
  min-width: 25px;
  line-height: 25px;
  font-size: 1.1em;  
}

.rec.rec-arrow:hover:enabled, .rec.rec-arrow:focus:enabled {
  background-color: #187fba;
}

.rec.rec-dot_active {
  background-color: #5fb7ea;
  box-shadow: 0 0 1px 3px #187fba;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
