body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden;
}

#gtx-trans {
  display: none !important;
}

dl {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
}

dt {
  flex: 0 0 60%;
  margin: 0;
  padding: 0;
}

dd {
  flex: 0 0 calc(40% - 5px);
  margin: 0 0 0 5px;
  padding: 0;
}

textarea,
select,
input,
button {
  outline: none;
  border: 0;
}

.sites-footer .react-toggle-track {
  margin-right: 5px;
}

.sites-footer .react-toggle--checked .react-toggle-track {
  background-color: #000000;
}

.sites-footer .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #666666;
}

.sites-footer .react-toggle--checked .react-toggle-thumb {
  border-color: #666666;
}

.vertical-text {
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-align: center;
  white-space: nowrap;
  height: auto;
  display: inline-block;
}